<template>
  <div class="container_con">
      <div class="new_search_box">
        <div style="margin-top:0px">
		<span class="mr_20">
		  时间：
		  <el-date-picker
		    v-model="queryParams.time"
		    type="daterange"  value-format="YYYY-MM-DD" 
		    placeholder="请选择时间"
		    range-separator="至" style="width: 300px;"
		    start-placeholder="开始日期"
		    end-placeholder="结束日期"
		  >
		  </el-date-picker>
		</span>
		<span class="mr_20">
		  产品编号：
          <el-input icon="el-icon-search" v-model="queryParams.product_no" @keyup.enter.native="handleQuery"
            placeholder="请输入产品编号" clearable style="width:300px;margin-right:10px"></el-input>
		</span>
		<span class="mr_20">
          品名：
		  <el-input icon="el-icon-search" v-model="queryParams.product_name" @keyup.enter.native="handleQuery"
            placeholder="请输入品名" clearable style="width:300px;margin-right:10px"></el-input>
		</span>
		<span class="mr_20">
          生产厂家：
		  <el-input icon="el-icon-search" v-model="queryParams.manufacturer" @keyup.enter.native="handleQuery"
            placeholder="请输入生产厂家" clearable style="width:300px;margin-right:10px"></el-input>
        </span>
            <el-button type="primary" @click="handleQuery()">搜索</el-button>
            <el-button @click="resetQuery()">重置</el-button>
        </div>
      </div>
      <div class="search">
        <div>
          <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/salesSlect/export')" :loading="is_export"
            @click="try_export">导出</el-button>
        </div>
      </div>
      <div style="margin-top:20px">
        <el-table :data="list" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="index" width="55" align="center" />
          <el-table-column prop="product_no" label="产品编号" />
          <el-table-column prop="product_name" label="品名" />
          <el-table-column prop="pack_unit" label="单位" />
          <el-table-column prop="manufacturer" label="生产厂家" />
          <el-table-column prop="xs_sum_product_num" label="销售数量" />
          <el-table-column prop="xsth_sum_product_num" label="退货数量" />
          <el-table-column prop="xt_sum_product_num" label="销退后 数量合计">
            <template v-slot="scope">
              <span style="color: red;font-weight: bold;">{{ scope.row.xt_sum_product_num || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="xt_sum_amount" label="销退金额合计">
            <template v-slot="scope">
              <span style="color: #409EFF;font-weight: bold;">{{ scope.row.xt_sum_amount || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cg_sum_product_num" label="采购数量" />
          <el-table-column prop="cgth_sum_product_num" label="采退数量" />
          <el-table-column prop="ct_sum_product_num" label="采退后 数量合计">
            <template v-slot="scope">
              <span style="color: red;font-weight: bold;">{{ scope.row.ct_sum_product_num || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ct_sum_amount" label="采退金额合计">
            <template v-slot="scope">
              <span style="color: #409EFF;font-weight: bold;">{{ scope.row.ct_sum_amount || 0 }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column fixed="right" label="操作" width="220">
              <template v-slot="scope">
                <el-button @click="handleDetail(scope.row)" type="text" v-if="have_auth('/productList/view')"
                  size="small">查看</el-button>
                <el-button type="text" size="small" @click="handleUpdate(scope.row)"
                  v-if="have_auth('/productList/edit')">编辑</el-button>
                <el-button type="text" size="small" @click="handleDelete(scope.row)"
                  v-if="have_auth('/productList/del')">删除</el-button>
                <el-button type="text" size="small" v-if="have_auth('/productList/editNumber')" @click="handleEditeNumber(scope.row)">编辑产品编号</el-button>
              </template>
            </el-table-column> -->
        </el-table>
      </div>

  </div>
  <pagination v-show="total > 0" :total="total" v-model:page="queryParams.page" v-model:limit="queryParams.size"
    @pagination="getList" />
  <importmodel ref="importmodel" @handleUploadSuccess="getList" />
</template>

<script>
import importmodel from "@/components/import";
export default {
  name: "list",
  components: {
    importmodel
  },
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        product_no: '',
        product_name: '',
        manufacturer: '',
        startTime: '',
        endTime: '',
      },
      total: 0,
      list: [],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      is_export: false,
      sysConfig: [],//产品前缀列表
      dialogVisible: false,
      form: {
        name: '',
        region: ''
      }
    };
  },
  created() {
    this.queryParams = window[this.$route.path] || {
      page: 1,
      size: 10,
      product_no: '',
      product_name: '',
      manufacturer: '',
      startTime: '',
      endTime: '',
    }
    this.getList();
  },
  watch: {
    $route(to, form) {
      window[form.path] = this.queryParams
    },
  },
  methods: {
    save_filter() {
      window[this.$route.path] = this.queryParams
    },
    resetQuery() {
      this.queryParams = {
        page: 1,
        size: 10,
        product_no: '',
        product_name: '',
        manufacturer: '',
        startTime: '',
        endTime: '',
      }
      this.getList()
    },
    try_import() {
      this.$refs.importmodel.init(2)
    },
    try_export() {
      if (this.is_export) { return false; }
      this.is_export = true;
	  let _data={...this.queryParams};
	  _data.startTime=_data.time?_data.time[0]:'';
	  _data.endTime=_data.time?_data.time[1]:'';
      this.$httpGet("/backend/product.Product/summaryExport", _data).then((res) => {
        if (res.status == 200) {
          var a = document.createElement('a');
          let name = res.data.file.split('/')
          var fileName = name[name.length - 1];
          a.download = fileName;
          a.href = this.$http + '/' + res.data.file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          this.$message.error(res.message);
        }
        this.is_export = false;
      }).catch((err) => {
        console.log(err);
        this.is_export = false;
      });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },

    /** 查询列表 */
    getList() {
      this.loading = true;
	  let _data={...this.queryParams};
	  _data.startTime=_data.time?_data.time[0]:'';
	  _data.endTime=_data.time?_data.time[1]:'';
      this.$httpGet("/backend/product.Product/summary", _data).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.list = res.message.data;
          this.total = res.message.total;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    }
  },
};
</script>

<style lang="scss" scoped></style>